<template>
  <div class="root">
    <div class="head" v-if="tablas[tabla].datos">
      <button @click="descargar">Descargar</button>
      <button @click="nuevo">Nuevo</button>
      <button @click="quitarfiltros">Filtros</button>
      <select v-model="tablas[tabla].registros">
        <option>50</option>
        <option>100</option>
        <option>150</option>
        <option>200</option>
        <option>300</option>
        <option>400</option>
        <option>500</option>
      </select>
      <span>{{tablas[tabla].datos.length}}/{{tablas[tabla].longitud}}</span>
    </div>
    <div class="container"> 
      <Tabla @link="link" @filtrar="e=>filtrar([e,tabla])" @ordenar="e=>ordenar([e,tabla])" v-if="tablas[tabla].campos && tablas[tabla].campos.length>0" @scroll="scroll" @evento="e=>cambiospreferencias([e,tabla])" ref="tabla" :key="key" :datos="tablas[tabla].datos" :campos="tablas[tabla].campos" :filtrable="true"/>
    </div>
    <Modal @salir="modal=false" v-if="modal"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Tabla from '@/components/Tabla.vue'
import Modal from '@/components/Modal.vue'
export default {
  name: 'Empresas',
  data: function (){
    return{
      tabla: 'empresas',
      key: 1,
      modal: false
    }
  },
  components: {
    Tabla,
    Modal
  },
  computed: {
    ...mapState(['tablas','cache'])
  },
  methods: {
    ...mapActions(['api','pedir','filtrar','ordenar','get_campos','cambiospreferencias','ver_mas','exportCSVFile']),
    async scroll(){
      let con = document.getElementById('container')
      if(con.scrollTop+con.clientHeight===con.scrollHeight){
        await this.ver_mas(this.tabla)
      }
    },
    descargar(){
      this.exportCSVFile([null,this.tablas[this.tabla].datos,this.tabla,'descargar'])
    },
    async link(e){
      let a = await this.api({
        archivo: 'madre',
        tipo: 'select',
        campos: ['*'],
        tabla: this.tabla.toUpperCase(),
        filtros: {
          ID: "='"+e.id+"'"
        },
        limite: 1,
        orden: null,
        respond: 'true',
        offset: 0
      })
      e.datos = a[0]
      this.cache.ventanas.push(e)
      this.modal = true
    },
    async nuevo(){
      // await this.api() // Aqui faltan muchos parametros
      // this.key++
    },
    quitarfiltros(){
      this.$refs.tabla.quitarfiltros()
    }
  },
  async beforeMount(){
    if(this.tablas[this.tabla].campos){
    }else{
      await this.get_campos(this.tabla)  
    }
    if(this.tablas[this.tabla].datos.length<=0){
      await this.pedir([0,null,this.tabla])
    }
  }
}
</script>

<style scoped>
.head{
  flex-grow: 0;
  color: white;
  width: 100%;
  text-align: right;
  background-color: rgb(255, 172, 116);
}
.container{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.container > div{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.root{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>